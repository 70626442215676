// @ts-nocheck

export const CONSENT_GRANTED = {
    'ad_storage': 'granted',
    'analytics_storage': 'granted',
    'personalization_storage': 'granted',
    'functionality_storage': 'granted',
    'security_storage': 'granted',
    'ad_user_data': 'granted',
    'ad_personalization': 'granted'
}

export const CONSENT_DENIED = {
    'ad_storage': 'denied',
    'analytics_storage': 'denied',
    'personalization_storage': 'denied',
    'functionality_storage': 'denied',
    'security_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied'
}

//gtag.js
export function gtag (...arg: any[]):void {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push(arguments)
}

export function initGtag ():void {
    // Set default consent state before any GTM/GA tag execution
    if (localStorage.getItem('consentMode') === 'true') {
        gtag('consent', 'default', CONSENT_GRANTED)
    } else {
        gtag('consent', 'default', CONSENT_DENIED)
    }

    // Load GTM/GA after consent has been set
    const gtagScript = document.createElement('script')
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${ import.meta.env.VITE_MEASUREMENT_ID }`
    gtagScript.async = true
    document.head.appendChild(gtagScript)

    gtag('js', new Date())
    gtag('config', import.meta.env.VITE_MEASUREMENT_ID);

    (function (w, d, s, l, i): void {
        w[l] = w[l] || []
        w[l].push({
            'gtm.start': new Date().getTime(), event: 'gtm.js'
        })
        const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
    })(window, document, 'script', 'dataLayer', import.meta.env.VITE_GTM_CONTAINER_ID)
}