import './bootstrap'
import '../css/app.css'

import { createApp, h, DefineComponent } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from 'ziggy-js'
import VueLazyload from 'vue3-lazyload'
import Hotjar from '@hotjar/browser'
import { gtag, initGtag } from './Composables/googleAnalyticsEvents'

createInertiaApp({
    title: (title) => `${ title }`,
    resolve: (name) => resolvePageComponent(`./Pages/${ name }.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup ({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        app.use(plugin)
            .use(ZiggyVue)
            .use(VueLazyload, {})


        // gtag start
        if (import.meta.env.VITE_GTM_CONTAINER_ID && import.meta.env.VITE_MEASUREMENT_ID) {
            //Initialize gtag function
            gtag()

            // Initialize gtag script with default consent state
            initGtag()
        }

        // Initialize Hotjar
        if (import.meta.env.VITE_HOTJAR_SITE_ID) {
            Hotjar.init(import.meta.env.VITE_HOTJAR_SITE_ID, import.meta.env.VITE_HOTJAR_VERSION, {
                debug: import.meta.env.VITE_HOTJAR_DEBUG_MODE === 'true'
            })
        }

        app.config.errorHandler = (error): void => {
            console.error('Inertia error:', error)
        }

        app.mount(el)
    },
    progress: {
        delay: 1,
        color: '#ff5852',
        showSpinner: true
    }
})
